import React, { useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import Editor from '@monaco-editor/react';
import { IconArrowsMaximize } from "@tabler/icons-react";
import axios from 'axios';
import { renderErrors } from '../../common/formHelpers';
import Tabs from '../Tabs';
import get from 'lodash/get';

const scriptTabs = [{ 'key': 'main', label: 'main.go' }, { 'key': 'mod', label: 'go.mod' }];

const ScriptLanguageSelector = ({
    type,
    script,
    setScript,
    mainScript,
    setMainScript,
    modScript,
    setModScript,
    expandEditor,
    setExpandEditor,
    error,
    addNotification,
    editMode,
    selectedLanguage,
    setSelectedLanguage,
    version,
    setVersion
}) => {
    const editorRef = useRef(null);
    const [templates, setTemplates] = useState(null);
    var { id, scriptTab } = useParams();
    const [goFileDisplay, setGoFileDisplay] = useState("main");
    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.target.value);
    };

    const handleVersionChange = (e) => {
        const template = templates.list.find(item => item.id === e.target.value);
        setMainScript(template.golang.main);
        setModScript(template.golang.mod);
        setVersion(e.target.value);
    };

    const onScriptTabChange = (tab) => {
        scriptTab = tab
        setGoFileDisplay(tab)
    }

    const handleEditorWillMount = (monaco) => {
        monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
            target: monaco.languages.typescript.ScriptTarget.ES6,
            allowNonTsExtensions: true,
            allowJs: true
        });
        if (type === "faas" && templates === null) {
            axios.get(`/mgmt-srv/functionTemplates`).then(response => {
                setTemplates(response.data);
                if (response.data.list && response.data.list.length > 0) {
                    setVersion(response.data.list[0].id);
                    setMainScript(response.data.list[0].golang.main);
                    setModScript(response.data.list[0].golang.mod);
                }
            }).catch(err => {
                addNotification({
                    message: get(err, 'response.data.message', 'An error occurred while fetching API data'),
                    type: 'error'
                });
            });
        }
        monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
    };

    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
    };

    const handleEditorChange = (value) => {
        setScript(value);
    };

    const handleMainEditorChange = (value) => {
        setMainScript(value);
    };

    const handleModEditorChange = (value) => {
        setModScript(value);
    };

    return (
        <div className="form-control w-full">
            {!expandEditor && (
                <>
                    <label className="label">
                        <span className="label-text">Select Scripting Language</span>
                    </label>
                    <div className="input-group">
                        <select
                            className="input input-bordered w-full"
                            value={selectedLanguage || ""}
                            onChange={handleLanguageChange}
                            disabled={!editMode}
                        >
                            <option value="">Select Language</option>
                            <option value="javascript">Javascript</option>
                            <option value="golang">Golang</option>
                        </select>
                    </div>
                </>
            )}
            {!expandEditor && type && type === "faas" && selectedLanguage === "golang" && (
                <>
                    <label className="label">
                        <span className="label-text">Select Template Version</span>
                    </label>
                    <div className="input-group">
                        <select
                            className="input input-bordered w-full"
                            value={version || ""}
                            onChange={handleVersionChange}
                            disabled={!editMode}
                        >
                            <option value="" disabled>Select a template</option>
                            {templates && templates.list.map((template) => (
                                <option key={template.id} value={template.id}>
                                    {template.name} - {template.version}
                                </option>
                            ))}
                        </select>
                    </div>
                </>
            )}
            {(!selectedLanguage || selectedLanguage === 'javascript' || selectedLanguage === 'Javascript') && (
                <div className="form-control w-full">
                    {!expandEditor && (
                        <div className="flex justify-between items-center">
                            <label className="label">
                                <span className="label-text">Script</span>
                            </label>
                            <div>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setExpandEditor(current => !current);
                                    }}
                                    className="btn btn-sm btn-ghost"
                                >
                                    <IconArrowsMaximize size={16} />
                                </button>
                            </div>
                        </div>
                    )}
                    <Editor
                        theme="vs-dark"
                        height={expandEditor ? "100vh" : "300px"}
                        options={{
                            fontSize: 15,
                            quickSuggestions: false,
                            scrollBeyondLastLine: false,
                            readOnly: !editMode,
                            minimap: { enabled: false }
                        }}
                        defaultLanguage="javascript"
                        value={script}
                        onChange={handleEditorChange}
                        beforeMount={handleEditorWillMount}
                        onMount={handleEditorDidMount}
                    />

                </div>
            )}


            {(selectedLanguage === 'golang' || selectedLanguage === 'Go' || selectedLanguage === 'go') && (
                <div className="form-control w-full">
                    <div className="flex justify-between items-center">
                        <label className="label">
                            <span className="label-text">Script</span>
                        </label>
                        <div>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setExpandEditor(current => !current);
                                }}
                                className="btn btn-sm btn-ghost"
                            >
                                <IconArrowsMaximize size={16} />
                            </button>
                        </div>
                    </div>
                    <Tabs onClick={(e) => {
                        e.preventDefault();
                    }} tabs={scriptTabs} activeTab={goFileDisplay} onTabChange={onScriptTabChange} />
                    {goFileDisplay === "mod" && (<Editor
                        theme="vs-dark"
                        height={expandEditor ? "100vh" : "300px"}
                        options={{
                            fontSize: 15,
                            quickSuggestions: false,
                            scrollBeyondLastLine: false,
                            readOnly: !editMode,
                            minimap: { enabled: false }
                        }}
                        defaultLanguage="go"
                        value={modScript}
                        onChange={handleModEditorChange}
                        beforeMount={handleEditorWillMount}
                        onMount={handleEditorDidMount}
                    />)}
                    {goFileDisplay === "main" && (<Editor
                        theme="vs-dark"
                        height={expandEditor ? "100vh" : "300px"}
                        options={{
                            fontSize: 15,
                            quickSuggestions: false,
                            scrollBeyondLastLine: false,
                            readOnly: !editMode,
                            minimap: { enabled: false }
                        }}
                        defaultLanguage="go"
                        value={mainScript}
                        onChange={handleMainEditorChange}
                        beforeMount={handleEditorWillMount}
                        onMount={handleEditorDidMount}
                    />)}
                    {renderErrors(error, 'mainScript')}
                    {renderErrors(error, 'modScript')}
                    {renderErrors(error, 'script')}
                </div>
            )}
        </div>
    );
};

export default ScriptLanguageSelector;
