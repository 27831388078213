import Editor from "@monaco-editor/react";

const EventDetails = ({ event, onCancel, onSendEvent }) => {

    return <div>
        <div className="space-y-3 px-4 py-5 sm:p-6">
            <div className="w-full">
                <label className="label"><span className="label-text">Event Path</span></label>
                <div className="mx-1 text-md">{event.path}</div>
            </div>
            <div className="w-full">
                <label className="label"><span className="label-text">Status</span></label>
                <div className="flex mx-1 text-md">{event.status}</div>
            </div>
            <div className="w-full">
                <label className="label">
                    <span className="label-text">Request</span>
                </label>
                <div className="mx-1"><Editor
                    theme="vs-dark"
                    height="220px"
                    options={{ 'fontSize': 15, quickSuggestions: false, scrollBeyondLastLine: false, readOnly: true, minimap: { enabled: false } }}
                    defaultLanguage="json"
                    value={JSON.stringify(event.requestBody, null, 2)}
                /></div>
            </div>
            <div className="w-full">
                <label className="label">
                    <span className="label-text">Response</span>
                </label>
                <div className="mx-1"><Editor
                    theme="vs-dark"
                    height="150px"
                    options={{ 'fontSize': 15, quickSuggestions: false, scrollBeyondLastLine: false, readOnly: true, minimap: { enabled: false } }}
                    defaultLanguage="plaintext"
                    value={event.responseBody}
                /></div>
            </div>
        </div>
        <div className="bg-base-200 px-4 py-3 justify-between sm:px-6 flex">
            <button className="btn btn-primary" onClick={onCancel}>Close</button>
        </div>
    </div>
}

export default EventDetails;