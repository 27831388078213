import { IconCheck, IconX } from '@tabler/icons-react';
import { Fragment, useState, useEffect } from "react";
import useNotifications from '../../../hooks/useNotifications';
import { renderErrors } from "../../../common/formHelpers";
import keys from 'lodash/keys';
import get from 'lodash/get';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal';
import Modal from '../../../components/Modal';
import ConfigBuilder from '../../../components/ConfigBuilder';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import { generateWorkspacePath } from '../../../common/urlHelpers';
import ScriptLanguageSelector from '../../../components/ScriptLanguageSelector';
import {decisionBlockScript, modScriptTemplate} from '../../../common/scripts';

const scriptTemplate = `module.exports = function (event, ctx, config) {
    //add your script here to transform or enrich the event

    //remember to return the transformed event object for the pipeline to continue processing the event
    return event;
}`;

const DecisionDetailsCard = (props) => {
    const { data: { id, name, script, active, config, language, golang }, onUpdate } = props;
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [changes, setChanges] = useState({});
    const [executing, setExecuting] = useState(false);
    const [configChanges, setConfigChanges] = useState(config ? cloneDeep(config) : []);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [expandEditor, setExpandEditor] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const workspacePath = generateWorkspacePath();

    const saveChanges = (e) => {
        e.preventDefault();
        let payload = props.data;
        payload.language = selectedLanguage;
        let golang = {
            main: "",
            mod: ""
        };
        keys(payload).forEach(key => {
            if (changes[key]) {
                payload[key] = changes[key];
            }
        })
        if (selectedLanguage === "golang") {
            golang = payload.golang;
            keys(payload.golang).forEach(key => {
                if (changes[key]) {
                    payload.golang[key] = changes[key];
                }
            });
        } else {
            payload.script = changes.script;
        }
        if (keys(payload).length === 0) {
            setEditMode(false);
            return;
        }
        setExecuting(true);
        axios.put(`${workspacePath}/transformations/${id}`, payload).then(response => {
            onUpdate(response.data);
            setEditMode(false);
            addNotification({
                message: 'Decision Block has been updated',
                type: 'success'
            });
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while updating Decision Block'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
        })
    }

    const deleteDecision = (e) => {
        e.preventDefault();
        setExecuting(true);
        axios.delete(`${workspacePath}/transformations/${id}`).then(response => {
            addNotification({
                message: 'Decision Block has been deleted',
                type: 'success'
            });
            navigate('/decisions');
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while deleting Decision Block'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
            setShowDeleteConfirmation(false);
        })
    }

    useEffect(() => {
        setChanges(current => ({
            ...current,
            config: filter(configChanges, item => item.key)
        }));
    }, [configChanges]);

    const cancelChanges = (e) => {
        e.preventDefault();
        setChanges({});
        setEditMode(false);
    }

    const exportAsApp = (e) => {
        e.preventDefault();
        setExecuting(true);
        axios.get(`${workspacePath}/transformations/${id}/export`).then(response => {
            addNotification({
                message: 'Decision Block has been exported',
                type: 'success'
            });
            navigate('/decisions');
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while deleting Decision Block'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
            setShowDeleteConfirmation(false);
        });
    };

    return (
        <div className="card bg-base-100 mb-4">
            <div className="card-body p-4">
                <div className="text-lg font-bold">Decision Block Details</div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">Decision Block Name</span>
                    </label>
                    {editMode ? (
                        <Fragment>
                            <input
                                type="text"
                                placeholder="Provide a name for the Decision Block"
                                defaultValue={name}
                                className="input input-bordered w-full"
                                onChange={e => setChanges(current => ({
                                    ...current,
                                    name: e.target.value
                                }))}
                            />
                            {renderErrors(error, 'name')}
                        </Fragment>
                    ) : (
                        <div className="p-1 text-lg">{name}</div>
                    )}
                </div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">Configuration</span>
                    </label>
                    <ConfigBuilder
                        config={configChanges}
                        setConfig={setConfigChanges}
                        readOnly={!editMode}
                    />
                    {renderErrors(error, 'config')}
                </div>


                <ScriptLanguageSelector
                    script={changes.script || script || scriptTemplate}
                    setScript={(value) => setChanges(current => ({
                        ...current,
                        script: value
                    }))}
                    mainScript={(golang && golang.main) || decisionBlockScript}
                    setMainScript={(value) => setChanges(current => ({
                        ...current,
                        main: value
                    }))}
                    modScript={(golang && golang.mod) || modScriptTemplate}
                    setModScript={(value) => setChanges(current => ({
                        ...current,
                        mod: value
                    }))}
                    expandEditor={expandEditor}
                    setExpandEditor={setExpandEditor}
                    error={error}
                    addNotification={addNotification}
                    workspacePath={workspacePath}
                    editMode={editMode}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                />
                <div className="flex">
                    <div className="form-control w-6/12">
                        <label className="label">
                            <span className="label-text">Active</span>
                        </label>
                        {editMode ? (
                            <Fragment>
                                <input
                                    type="checkbox"
                                    className={`toggle toggle-lg ${(changes.active || active) ? 'toggle-success' : ''}`}
                                    defaultChecked={active}
                                    onChange={e => setChanges(current => ({
                                        ...current,
                                        active: e.target.checked
                                    }))}
                                />
                                {renderErrors(error, 'active')}
                            </Fragment>
                        ) : (
                            <div className="p-1">
                                {active ? (
                                    <IconCheck className="text-success" size={24} />
                                ) : (
                                    <IconX className="text-error" size={24} />
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className="card-actions mt-2 justify-between">
                    <div>
                        {editMode && (
                            <button
                                className="btn btn-error"
                                type="button"
                                disabled={executing}
                                onClick={() => setShowDeleteConfirmation(true)}
                            >
                                Delete
                            </button>
                        )}
                    </div>
                    <div className="flex justify-end">
                        {!editMode && (
                            <a
                                className="btn btn-info mx-2"
                                target="_blank"
                                rel="noreferrer"
                                onClick={exportAsApp}
                            >
                                Export as App
                            </a>
                        )}
                        {!editMode && (
                            <button className="btn" type="button" onClick={() => setEditMode(true)}>
                                Edit Decision Block
                            </button>
                        )}
                        {editMode && (
                            <button className="btn mr-2" type="button" onClick={cancelChanges}>
                                Cancel
                            </button>
                        )}
                        {editMode && (
                            <button
                                className="btn btn-primary"
                                type="button"
                                disabled={executing}
                                onClick={saveChanges}
                            >
                                Save Changes
                            </button>
                        )}
                    </div>
                </div>
                {(showDeleteConfirmation && <ConfirmModal
                    title="Delete Decision Block"
                    message="Are you sure you want to delete this Decision Block?"
                    onConfirm={deleteDecision}
                    onCancel={() => setShowDeleteConfirmation(false)}
                />
                )}
                {expandEditor && (
                    <Modal
                        large={true}
                        title={editMode ? 'Script' : 'Script (Read-only)'}
                        onCancel={() => setExpandEditor(false)}
                    >
                        <>
                            <div className="px-6 pb-4">
                                <ScriptLanguageSelector
                                    script={changes.script || script}
                                    setScript={(value) => setChanges(current => ({
                                        ...current,
                                        script: value
                                    }))}
                                    mainScript={(golang && golang.main) || decisionBlockScript}
                                    setMainScript={(value) => setChanges(current => ({
                                        ...current,
                                        main: value
                                    }))}
                                    modScript={(golang && golang.mod) || modScriptTemplate}
                                    setModScript={(value) => setChanges(current => ({
                                        ...current,
                                        mod: value
                                    }))}
                                    expandEditor={expandEditor}
                                    setExpandEditor={setExpandEditor}
                                    error={error}
                                    addNotification={addNotification}
                                    workspacePath={workspacePath}
                                    editMode={editMode}
                                    selectedLanguage={selectedLanguage}
                                    setSelectedLanguage={setSelectedLanguage}
                                />
                            </div>
                            <div className="bg-base-200 px-4 py-3 justify-between sm:px-6 flex">
                                <button
                                    disabled={editMode}
                                    className="btn btn-primary mr-2"
                                    onClick={() => setEditMode(true)}
                                >
                                    Edit
                                </button>
                                <button className="btn" onClick={() => setExpandEditor(false)}>
                                    Collapse
                                </button>
                            </div>
                        </>
                    </Modal>
                )}
            </div>
        </div>
    );
}

export default DecisionDetailsCard;
