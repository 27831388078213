import { IconArrowRight, IconCheck, IconClipboard, IconEye, IconEyeOff, IconX } from '@tabler/icons-react';
import { useCallback, useEffect, useState } from "react";
import useNotifications from '../../../hooks/useNotifications';
import { renderErrors } from "../../../common/formHelpers";
import keys from 'lodash/keys';
import get from 'lodash/get';
import filter from 'lodash/filter';
import axios from 'axios';
import ConfirmModal from '../../../components/ConfirmModal';
import Modal from '../../../components/Modal';
import { Link, useNavigate } from 'react-router-dom';
import HeaderBuilder from '../../../components/HeaderBuilder';
import cloneDeep from 'lodash/cloneDeep';
import { generateWorkspacePath } from '../../../common/urlHelpers';
import { IconPlus, IconSearch } from "@tabler/icons-react";
import ItemSelectorModal from "../../../components/ItemSelectorModal";
import ConfigurationDetailsCard from '../../Configuration/ConfigurationDetails/ConfigurationDetailsCard';
import ScriptLanguageSelector from '../../../components/ScriptLanguageSelector';

const scriptTemplate = `module.exports = {
    setup: function(config) { /* optional */
        //setup connection
    },
    execute: function(event, ctx, config) { /* required */
        //send to destination.
    },
    teardown: function(config) { /* optional */
        //teardown connection
    }
}`;

const FunctionDetailsCard = (props) => {
    const { data: { id, name, secured, active, headers, captureRemoteIP, script, golang, language, templateId }, onUpdate = () => { }, readOnly = false } = props;
    const apiRef = props.data?.apiAccessRef || '';
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [changes, setChanges] = useState({});
    const [executing, setExecuting] = useState(false);
    const [setShowConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [headerChanges, setHeaderChanges] = useState(headers ? cloneDeep(headers) : []);
    const [configuration, setConfiguration] = useState(null);
    const [showConfigurationSelector, setShowConfigurationSelector] = useState(false);
    const [expandEditor, setExpandEditor] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const [version, setVersion] = useState(null);
    const workspacePath = generateWorkspacePath();
    const configPath = `${workspacePath}/configurations?type=APIAccess`

    const copyToClipboard = (key, message) => {
        navigator.clipboard.writeText(key).then(() => {
            addNotification({
                message,
                type: 'info'
            })
        }).catch((error) => {
            addNotification({
                message: "Error writing to clipboard"
            })
        });
    }

    const onConfigurationSelection = (item) => {
        setConfiguration(item);
        setShowConfigurationSelector(false);
    }

    useEffect(() => {
        setChanges(current => ({
            ...current,
            headers: filter(headerChanges, item => item.key)
        }));
    }, [headerChanges]);

    useEffect(() => {
        if (apiRef) {
            axios.get(`${workspacePath}/configurations/${apiRef}`).then(response => {
                setConfiguration(response.data)

            }).catch(err => {
                addNotification({
                    message: get(err, 'response.data.message', 'An error occurred while fetching API data'),
                    type: 'error'
                });
            });
        }
    }, [apiRef]);

    const saveChanges = (e) => {
        e.preventDefault();

        let payload = props.data;
        payload.apiAccessRef = configuration.id;
        keys(payload).forEach(key => {
            if (changes[key]) {
                payload[key] = changes[key];
            }
        })

        if (keys(payload).length === 0) {
            setEditMode(false);
            return;
        }
        payload.templateId = version;
        setExecuting(true);
        axios.put(`${workspacePath}/functions/${id}`, payload).then(response => {
            onUpdate(response.data);
            setEditMode(false);
            addNotification({
                message: 'Function has been updated',
                type: 'success'
            });
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while updating Function'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
        })
    }

    const deleteSource = (e) => {
        e.preventDefault();
        setExecuting(true);
        axios.delete(`${workspacePath}/functions/${id}`).then(response => {
            addNotification({
                message: 'Function has been deleted',
                type: 'success'
            });
            navigate('/functions');
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while deleting Function'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
            setShowDeleteConfirmation(false);
        })
    }

    const cancelChanges = (e) => {
        e.preventDefault();
        setChanges({});
        setHeaderChanges(headers ? cloneDeep(headers) : []);
        setEditMode(false);
    }

    const cancelDialog = useCallback(() => {
        setShowConfirmation(false)
    }, []);

    const renderCommonDetails = () => {
        return <><div className="form-control w-full">
            <label className="label">
                <span className="label-text">Event URL</span>
            </label>
            <div className="p-1 break-all">{`${window.location.origin}/faas-srv/function/execute/${id}`}
                <button title="Copy to Clipboard" className="ml-2 btn btn-ghost btn-xs"
                    onClick={() => copyToClipboard(`${window.location.origin}/faas-srv/function/execute/${id}`, "URL has been copied to Clipboard")}>
                    <IconClipboard size={16} /></button>
            </div>
        </div>
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Batch Event URL</span>
                </label>
                <div className="p-1 break-all">{`${window.location.origin}/faas-srv/function/execute/${id}/batch`}
                    <button title="Copy to Clipboard" className="ml-2 btn btn-ghost btn-xs"
                        onClick={() => copyToClipboard(`${window.location.origin}/faas-srv/function/execute/${id}/batch`, "URL has been copied to Clipboard")}>
                        <IconClipboard size={16} /></button>
                </div>
            </div>
        </>
    };

    const renderEditableForm = () => {
        return <>
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Function Name</span>
                </label>
                <input type="text" placeholder="Provide a name for the Function" defaultValue={name}
                    className="input input-bordered w-full"
                    onChange={e => setChanges(current => ({
                        ...current,
                        name: e.target.value
                    }))} />
                {renderErrors(error, 'name')}
            </div>
            <div className="flex">
                <div className="form-control w-6/12">
                    <label className="label">
                        <span className="label-text">Secured</span>
                    </label>
                    <input type="checkbox" className={`toggle toggle-lg ${secured ? 'toggle-success' : ''}`} defaultChecked={secured}
                        onChange={e => setChanges(current => ({
                            ...current,
                            secured: e.target.checked
                        }))} />
                    {renderErrors(error, 'secured')}
                </div>
                <div className="form-control w-6/12">
                    <label className="label">
                        <span className="label-text">Active</span>
                    </label>
                    <input type="checkbox" className={`toggle toggle-lg ${(changes.active || active) ? 'toggle-success' : ''}`} defaultChecked={active}
                        onChange={e => setChanges(current => ({
                            ...current,
                            active: e.target.checked
                        }))} />
                    {renderErrors(error, 'active')}
                </div>

                <div className="form-control w-6/12">
                    <label className="label">
                        <span className="label-text">Capture Remote IP</span>
                    </label>
                    <input type="checkbox" className={`toggle toggle-lg ${(changes.captureRemoteIP || captureRemoteIP) ? 'toggle-success' : ''}`} defaultChecked={captureRemoteIP}
                        onChange={e => setChanges(current => ({
                            ...current,
                            captureRemoteIP: e.target.checked
                        }))} />
                    {renderErrors(error, 'captureRemoteIP')}
                </div>
            </div>

            <div className="form-control w-full text-lg font-bold bg-base-200 p-2 rounded-md" style={{ marginTop: "1rem" }}>Configuration </div>

            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Select a Configuration</span>
                </label>
                <div className="input-group">
                    <input type="text" readOnly disabled placeholder="Select" value={configuration?.name || ''} className="input input-bordered" />
                    <button className="btn btn-square tooltip p-3" data-tip="Select a Configuration" onClick={e => { e.preventDefault(); setShowConfigurationSelector(true) }}>
                        <IconSearch width={24} />
                    </button>

                    <button className="btn btn-square tooltip p-2" data-tip="Create Configuration" onClick={e => { e.preventDefault(); }}>
                        <IconPlus width={24} />
                    </button>

                </div>
                {renderErrors(error, 'configuration')}
            </div>
            {configuration?.name &&
                <div className="p-1 text-lg">
                    <ConfigurationDetailsCard data={configuration} isCompactMode={true} />
                </div>
            }

            {renderCommonDetails()}

            {showConfigurationSelector && <ItemSelectorModal url={configPath} title="Select Configuration" cols={[{ "label": "Name", "datacss": "text-left", "css": "w-1/2", "name": "name" }, { "label": "Type", "datacss": "", "css": "w-1/2", "name": "type" }, { "label": "Preview", "datacss": "text-left", "css": "w-1/2", "name": "preview" }]}
                onCancel={() => setShowConfigurationSelector(false)} onSelect={onConfigurationSelection} />}

            <ScriptLanguageSelector
                type="faas"
                script={changes.script || script}
                setScript={(value) => setChanges(current => ({
                    ...current,
                    script: value
                }))}
                mainScript={(golang && golang.main)}
                setMainScript={(value) => setChanges(current => ({
                    ...current,
                    main: value
                }))}
                modScript={(golang && golang.mod)}
                setModScript={(value) => setChanges(current => ({
                    ...current,
                    mod: value
                }))}
                expandEditor={expandEditor}
                setExpandEditor={setExpandEditor}
                error={error}
                addNotification={addNotification}
                workspacePath={workspacePath}
                editMode={editMode}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                version={version}
                setVersion={setVersion}
            />

            {!readOnly && <div className="card-actions mt-2 justify-between">
                <div><button className="btn btn-error" type="button" disabled={executing} onClick={() => setShowDeleteConfirmation(true)}>Delete</button></div>
                <div className="flex justify-end">

                    <button className="btn mr-2" onClick={cancelChanges}>Cancel</button>
                    <button className="btn btn-primary" disabled={executing} onClick={saveChanges}>Save
                        Changes</button>
                </div>
            </div>}
        </>
    }

    const renderReadOnlyForm = () => {

        return <>
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Function Name</span>
                </label>
                <div className="p-1 text-lg">{name}</div>
            </div>
            <div className="flex">
                <div className="form-control w-6/12">
                    <label className="label">
                        <span className="label-text">Secured</span>
                    </label>

                    <div className="p-1">{secured ? <IconCheck className="text-success" size={24} /> : <IconX className="text-error" size={24} />}</div>
                </div>
                <div className="form-control w-6/12">
                    <label className="label">
                        <span className="label-text">Active</span>
                    </label>

                    <div className="p-1">{active ? <IconCheck className="text-success" size={24} /> : <IconX className="text-error" size={24} />}</div>
                </div>
                <div className="form-control w-6/12">
                    <label className="label">
                        <span className="label-text">Capture Remote IP</span>
                    </label>

                    <div className="p-1">{captureRemoteIP ? <IconCheck className="text-success" size={24} /> : <IconX className="text-error" size={24} />}</div>
                </div>
            </div>
            {renderCommonDetails()}
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Selected Configuration</span>
                </label>
                <div className="p-1 text-lg">{configuration?.name}</div>
                {renderErrors(error, 'configuration')}
            </div>

            <div className="text-lg font-bold bg-base-200 p-2 rounded-md flex justify-between items-center">
                <span>Configuration Details</span>
                <Link className="btn btn-sm btn-ghost px-2" to={`/configurations/${configuration?.id}`}><IconArrowRight className="text-primary" size={24}></IconArrowRight></Link>
            </div>
            {configuration?.name &&
                <div>
                    <ConfigurationDetailsCard data={configuration} isCompactMode={true} />
                </div>
            }

            <ScriptLanguageSelector
                type="faas"
                script={changes.script || script}
                setScript={(value) => setChanges(current => ({
                    ...current,
                    script: value
                }))}
                mainScript={(golang && golang.main)}
                setMainScript={(value) => setChanges(current => ({
                    ...current,
                    main: value
                }))}
                modScript={(golang && golang.mod)}
                setModScript={(value) => setChanges(current => ({
                    ...current,
                    mod: value
                }))}
                expandEditor={expandEditor}
                setExpandEditor={setExpandEditor}
                error={error}
                addNotification={addNotification}
                workspacePath={workspacePath}
                editMode={editMode}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                version={templateId}
                setVersion={setVersion}
            />

            {!readOnly && <div className="card-actions mt-2 justify-between">
                <div></div>
                <div className="flex justify-end">
                    <button className="btn" onClick={() => setEditMode(true)}>Edit Function</button>
                </div>
            </div>}
        </>
    }

    return <div className="card bg-base-100 mb-4">
        <div className="card-body p-4">
            <div className="text-lg font-bold bg-base-200 p-2 rounded-md">Function Details</div>
            {editMode ? renderEditableForm() : renderReadOnlyForm()}
        </div>

        {showDeleteConfirmation && <ConfirmModal title="Delete Function" message="Are you sure you want to delete this Function? All associated events related to this Function will be deleted." onConfirm={deleteSource} onCancel={() => setShowDeleteConfirmation(false)} />}
        {expandEditor && (
            <Modal large={true} title={editMode ? 'Script' : 'Script (Read-only)'} onCancel={() => setExpandEditor(false)}>
                <>
                    <div className="px-6 pb-4">
                        <ScriptLanguageSelector
                            type="faas"
                            script={changes.script || script}
                            setScript={(value) => setChanges(current => ({
                                ...current,
                                script: value
                            }))}
                            mainScript={(golang && golang.main)}
                            setMainScript={(value) => setChanges(current => ({
                                ...current,
                                main: value
                            }))}
                            modScript={(golang && golang.mod)}
                            setModScript={(value) => setChanges(current => ({
                                ...current,
                                mod: value
                            }))}
                            expandEditor={expandEditor}
                            setExpandEditor={setExpandEditor}
                            error={error}
                            addNotification={addNotification}
                            workspacePath={workspacePath}
                            editMode={editMode}
                            selectedLanguage={selectedLanguage}
                            setSelectedLanguage={setSelectedLanguage}
                            version={templateId}
                            setVersion={setVersion}
                        />
                    </div>
                    <div className="bg-base-200 px-4 py-3 justify-between sm:px-6 flex">
                        <button disabled={editMode} className="btn btn-primary mr-2" onClick={() => setEditMode(true)}>Edit</button>
                        <button className="btn" onClick={() => setExpandEditor(false)}>Collapse</button>
                    </div>
                </>
            </Modal>
        )}
    </div>
}

export default FunctionDetailsCard;